<template>
	<div>
		<header class="flex flex-row justify-between p-4 border-b-1 border-gray-004">
			<button 
				v-html="title"
				@click="toggleAccordion"
			></button>
			<button @click="toggleAccordion">
				<Icon 
					class="text-24px"
					:icon="isExpanded ? 'mdi:menu-up' : 'mdi:menu-down'" 
				/>
			</button>
		</header>
		<section 
			ref="wrapEl"
			class="overflow-hidden"
			:class="{
				'border-b-1 border-gray-004': isExpanded
			}"
			:style="{
				height: 0
			}"
		>
			<div 
				ref="innerEl"
				class="h-full py-x2"
			>
				<div class="relative w-full h-full">
					<div class="absolute inset-0 overflow-x-hidden overflow-y-auto">
						<div ref="bodyEl" class="px-x2">
							<div 
								class="flex flex-col gap-x2"
								v-html="body"
							></div>
						</div>
					</div>
				</div>
			</div>
		</section>
	</div>
</template>

<script setup>
import { ref, defineProps, defineEmits, computed, onMounted, watch, onUnmounted } from 'vue'
import { Icon } from '@iconify/vue'

import gsap from 'gsap'

const emit = defineEmits()
const props = defineProps({
	data: {
		type: Object,
		required: true
	},
	maxHeight: {
		type: String,
		default: null
	}
})

const tl = gsap.timeline({
	duration: 0.1,
	ease: 'power1.out'
})

const wrapEl = ref(null)
const innerEl = ref(null)
const bodyEl = ref(null)

const ndx = computed(() => {
	return props.data?.ndx
})

const title = computed(() => {
	return props.data?.title
})

const body = computed(() => {
	return props.data?.body
})

const isExpanded = computed(() => {
	return props.data?.is_expanded
})

onMounted(() => {
	animate()

	window.addEventListener('resize', resizeEvent)
})

onUnmounted(() => {
	window.removeEventListener('resize', resizeEvent)
})

watch(isExpanded, () => {
	animate()
})

const toggleAccordion = () => {
	if (isExpanded.value) collapse()
	else expand()
}

const expand = () => {
	emit('expand', ndx.value)
}

const collapse = () => {
	emit('collapse', ndx.value)
}

const animate = () => {
	const computedInnerEl = window.getComputedStyle(innerEl.value)

	tl.to(wrapEl.value, {
		height: isExpanded.value
			? props.maxHeight ? parseInt(props.maxHeight) : bodyEl.value.offsetHeight
				+ parseInt(computedInnerEl.paddingTop) 
				+ parseInt(computedInnerEl.paddingBottom)
				+ 1
			: '0px'
	})
}

const resizeEvent = () => {
	const computedInnerEl = window.getComputedStyle(innerEl.value)
	const bodyHeight = bodyEl.value.offsetHeight
	+ parseInt(computedInnerEl.paddingTop) 
	+ parseInt(computedInnerEl.paddingBottom)
	+ 1
	
	// resize if expanded + max height not set + body height changes
	if (isExpanded.value && !props.maxHeight && (wrapEl.value.offsetHeight !== bodyHeight)) {
		animate()
	}
}
</script>