import { createApp, defineCustomElement } from 'vue'
import App from './App.vue'

import {
	FancyBox,
	BreakingMedFeed,
	CognitiveSearch,
	IhpBanner,
	IraeSearch,
	Accordion,
	PreTest,
	PostTest,
	PollingQuestion,
	CMEInformation,
	UniversalChatbot,
  LinkedInTracker
} from './views/'

const FancyBoxElement = defineCustomElement(FancyBox)
const BreakingMedFeedElement = defineCustomElement(BreakingMedFeed)
const IraeSearchElement = defineCustomElement(IraeSearch)
const CognitiveSearchElement = defineCustomElement(CognitiveSearch)
const IhpBannerElement = defineCustomElement(IhpBanner)
const AccordionElement = defineCustomElement(Accordion)
const PreTestElement = defineCustomElement(PreTest)
const PostTestElement = defineCustomElement(PostTest)
const PollingQuestionElement = defineCustomElement(PollingQuestion)
const CMEInformationElement = defineCustomElement(CMEInformation)
const UniversalChatbotElement = defineCustomElement(UniversalChatbot)
const LinkedInTrackerElement = defineCustomElement(LinkedInTracker)

customElements.define('fancy-box', FancyBoxElement)
customElements.define('breakingmed-feed', BreakingMedFeedElement)
customElements.define('irae-search', IraeSearchElement)
customElements.define('cognitive-search', CognitiveSearchElement)
customElements.define('ihp-banner', IhpBannerElement)
customElements.define('pik-accordion', AccordionElement)
customElements.define('pik-pretest', PreTestElement)
customElements.define('pik-posttest', PostTestElement)
customElements.define('polling-question', PollingQuestionElement)
customElements.define('cme-information', CMEInformationElement)
customElements.define('universal-chatbot', UniversalChatbotElement)
customElements.define('linkedin-tracker', LinkedInTrackerElement)

createApp(App)
	.mount('#widget-getter-demo')