import { createStore } from 'vuex' 
// import axios from 'axios'

const state = () => {
	return {
    widgets: [
      {
        id: 'breakingmed-feed',
        label: 'BreakingMED Feed'
      },
      {
        id: 'cognitive-search',
        label: 'Cognitive Search'
      },
      {
        id: 'irae-search',
        label: 'IrAE Search'
      },
      // {
      // 	id: 'pik-accordion',
      // 	label: 'Accordion'
      // },
      {
        id: 'ihp-banner',
        label: 'IHP Banner'
      },
      {
        id: 'ihp-banner-180x150',
        label: 'IHP Banner 180x150'
      },
      {
        id: 'pik-pretest',
        label: 'Pretest'
      },
      {
        id: 'pik-posttest',
        label: 'Post Test'
      },
      {
        id: 'cme-information',
        label: 'CME Information'
      },
      {
        id: 'universal-chatbot',
        label: 'Universal Chatbot'
      },
      {
        id: 'linkedin-tracker',
        label: 'LinkedIn Tracker'
      }
    ],
		currWidget: 'pik-posttest',
    widgetSettings: null,
		breakingMedFeed: {
			endpoint: 'https://secureapi.atpoc.com/api-contentstream/latest/articles_widget',
			logos: {
				dark: 'https://cdn.atpoc.com/cdn/assets/production/breakingmed/logos/brmed-kaplan-logo-png/BreakingMED-Kaplan-Color-DarkBG.png',
				light: 'https://cdn.atpoc.com/cdn/assets/production/breakingmed/logos/brmed-kaplan-logo-png/BreakingMED-Kaplan-Color-WhiteBG.png'
			}
		},
		ihpFeed: {
			endpoint: 'https://cdn.atpoc.com/cdn/ihp',
			heading: 'Interactive CME-Accredited Patient Simulator'
		},
		chatbot: {
			copy: {
				prod: 'https://secureapi.atpoc.com/api-chatbot/1.0/router_chatbot_copy_prod',
				stage: 'https://secureapi.atpoc.com/api-chatbot/1.0/router_chatbot_copy_stage',
				dev: 'https://secureapi.atpoc.com/api-chatbot/1.0/router_chatbot_copy_dev'
			},
			router: {
				prod: 'https://secureapi.atpoc.com/api-chatbot/1.0/router_chatbot_prod',
				stage: 'https://secureapi.atpoc.com/api-chatbot/1.0/router_chatbot_stage',
				dev: 'https://secureapi.atpoc.com/api-chatbot/1.0/router_chatbot_dev'
			},
			autocomplete: 'https://secureapi.atpoc.com/api-chatbot/1.0/suggest-char-to-token',
		}
	}
}

const mutations = {
	setCurrWidget(state, val) {
		state.currWidget = val
	},
  setWidgetSettings(state, val) {
    state.widgetSettings = val
  }
}

const actions = {
	async CRUD(_, props) {
		const {
			url,
			method,
			headers,
			payload
		} = props

		const input = {
			method
		}
		let output

		if (headers) input.headers = headers
		if (payload) input.body = JSON.stringify(payload)

		if (method === 'PUT' || method === 'POST') {
			const req = await fetch(url, input)
			output = await req.json()
		}
		else {
			const req = await fetch(url, input)
			output = await req.json()
		}

		return output
	},
	async submitAnalytics(_, payload) {
		const defaultData = {
			app_id: '20000',
			appname: location.href,
			apptype: 'breakingmed',
			category: 'poc',
			metavalue: `${ location.href } | BreakingMED`,
			event: null,
			label: null,
			platform: 'web',
			session: null,
			token: state.userToken,
			userAgent: navigator.userAgent,
			value: 1,
		}

		const params = {}
	
		const body = Object.assign(defaultData, payload || {})

		if (body?.token !== '') params.token = body.token
	
		const urlParams = Object
			.keys(params)
			.map((key) => { 
				return key + '=' + params[key]
			})
			.join('&')
			|| null
	
		const url = process.env.VUE_APP_ANALYTICS_API_URL + (urlParams ? '?' + urlParams : '')

		console.log(url)
		console.log(body)

		// await axios.post(url, body, {
		// 	headers: {
		// 		'content-type': 'text/json'
		// 	}
		// })
		// 	.then(() => { console.log(body) })
		// 	.catch((e) => console.log(e))
	},
	toggleWidget({ commit }, props) {
		const { id } = props
		commit('setCurrWidget', id)
	},
  submitWidgetSettings({ commit }, payload) {
    commit('setWidgetSettings', payload)
  },
  clearWidgetSettings({ commit }) {
    commit('setWidgetSettings', null)
  }
}

const getters = {
  widgets(state) {
    return state.widgets
  },
	currWidget(state) {
		return state.widgets.filter((w) => w.id === state.currWidget)[0]
	},
  widgetSettings(state) {
    return state.widgetSettings
  },
	breakingMedFeed(state) {
		return state.breakingMedFeed
	},
	ihpFeed(state) {
		return state.ihpFeed
	},
	chatbot(state) {
		return state.chatbot
	}
}

export default createStore({
	state,
	mutations,
	actions,
	getters
})