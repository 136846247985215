<template>
	<div 
		class="loading-spinner flex flex-row justify-center items-center"
		:style="containerStyle"
	>
		<div class="w-full container loading-body relative flex flex-row justify-center items-center rounded-default">
			<div
				class="spinner rounded-full"
				:style="spinnerStyle"
			></div>
		</div>
	</div>
</template>

<script setup>
import { computed, defineProps } from 'vue'

const props = defineProps({
	color: {
		type: String,
		default: '#3498db'
	},
	bgColor: {
		type: String,
		default: 'gray-002'
	},
	size: {
		type: String,
		default: '120'
	},
	thickness: {
		type: String,
		default: '16'
	},
	height: {
		type: String,
		default: 'auto'
	}
})

const borderColor = computed(() => {
	return props?.color.includes('#')
		? props?.color
		: `var(--color-${ props?.color })`
})

const borderBgColor = computed(() => {
	return props?.bgColor.includes('#')
		? props?.bgColor
		: `var(--color-${ props?.bgColor })`
})
 
const spinnerStyle = computed(() => {
	return `width: ${ props?.size }px; height: ${ props?.size }px; border: ${ props?.thickness }px solid ${ borderBgColor.value }; border-top: ${ props?.thickness }px solid ${ borderColor.value }; animation: spin 2s linear infinite;`
})

const containerStyle = computed(() => {
	return `height: ${ props.height }; min-height: ${ props.height }; max-height: ${ props.height };`
})
</script>

<style src="@/assets/css/tailwind.css"></style>
<style src="@/assets/css/_fontface.css"></style>
<style src="@/assets/css/_variables.css"></style>
<style src="@/assets/css/_global.css"></style>
<style src="@/assets/css/_animations.css"></style>
<style src="@/assets/css/main.css"></style>
