<template>
	<div 
		class="pik-widget cognitive-search px-x2 py-x3 laptop:px-14 bg-white"
		:style="`background-image: url('${ bgImage }');`"
	>
		<div class="wrapper flex flex-col gap-default">
			<header class="header flex flex-col gap-default">
				<h4 class="font-medium">{{ title }}</h4>
				<p 
					v-if="description"
					class="text-12px tablet:text-base"
					v-html="description"
				></p>
			</header>
			<section class="content cognitive-searchbar relative w-full flex flex-row flex-nowrap gap-4 items-center text-12px">
				<div 
					class="flex-1 flex flex-row flex-nowrap items-center border-1 rounded-md overflow-hidden bg-white"
					:class="{
						'border-red': isActive
					}"
				>
					<form 
						class="flex-1 p-2 bg-white"
						@submit.prevent="submitSearch"
					>
						<input 
							type="text" 
							ref="searchBar"
							v-model="query"
							@focus="activate"
							@blur="inactivate"
							:placeholder="placeholder"
						/>
					</form>
					<UiSpacer 
						width="1px" 
						height="28px" 
						color="form-field-border" 
					/>
					<div class="p-2 bg-white">
						<UiLogo 
							:src="logo"
							max-width="48px"
							class="cursor-pointer"
							@click="focusSearchBar"
						/>
					</div>
				</div>
			</section>
		</div>
	</div>
</template>

<script setup>
import { ref, defineProps, defineEmits } from 'vue'

import UiLogo from '@/components/ui/Logo'
import UiSpacer from '@/components/ui/Spacer'

const emit = defineEmits(['search-cognitive'])
const props = defineProps({
	bgImage: {
		type: String,
		default: 'https://cdn.atpoc.com/cdn/assets/production/cognitiveSearch/background.png'
	},
	title: {
		type: String,
		default: 'Ask Cognitive'
	},
	description: {
		type: String,
		default: null
	},
	placeholder: {
		type: String,
		default: 'Ask your clinical question here...'
	},
	logo: {
		type: String,
		default: 'https://cdn.atpoc.com/assets/production/cognitive/ask-cognitive.png'
	}
})

const isActive = ref(false)
const query = ref(null)
const searchBar = ref (null)

const activate = () => {
	isActive.value = true
}

const inactivate = () => {
	isActive.value = false
}

const focusSearchBar = () => {
	searchBar.value.focus()
}

const submitSearch = () => {
	emit('search-cognitive', { query: query.value })
	query.value = ''
}
</script>

<style src="@/assets/css/tailwind.css"></style>
<style src="@/assets/css/_fontface.css"></style>
<style src="@/assets/css/_variables.css"></style>
<style src="@/assets/css/_global.css"></style>
<style src="@/assets/css/_animations.css"></style>
<style src="@/assets/css/main.css"></style>

<style scoped>
.cognitive-search {
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
}
.wrapper { width: 100%; }
.header { max-width: 280px; }

@media (min-width: 767px) {
	.wrapper { max-width: 360px; }
}
</style>