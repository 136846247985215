<template>
	<div 
		class="pik-widget posttest w-full h-full text-sm leading-4"
	>
		<div v-if="fancyBoxImg" class="fancy-box fixed inset-0">
			<div 
				class="absolute w-full h-full bg-black opacity-30"
				@click="fancyBoxImg = null"
			></div>
			<div 
				class="absolute w-full h-full p-x2 pointer-events-none"
			>
				<img 
					class="m-auto max-w-full max-h-full object-contain"
					:src="fancyBoxImg" 
				/>
			</div>
			<button 
				class="absolute top-0 right-0 m-x1 px-4 py-2 rounded-2xl bg-black text-white text-xs border-white border-1 border-solid"
				@click="fancyBoxImg = null"
			>minimize image</button>
		</div>
		<template v-if="isLoading">
			<div class="w-full min-height h-full flex flex-row justify-center items-center">
				<LoadingSpinner
				></LoadingSpinner>
			</div>
		</template>
		<template v-else>
			<div class="w-full h-full flex flex-col justify-center items-center">
				<div 
					v-if="isMissingJobnum"
					class="w-full text-center p-x2 font-medium text-20px bg-black text-white"
				>
					<p>Missing Jobnumber</p>
				</div>
				<div 
					v-else-if="isMissingToken"
					class="w-full text-center p-x2 font-medium text-20px bg-black text-white"
				>
					<p>Please <a 
						class="underline" 
						:href="loginUrl"
						style="text-underline-offset: 4px;"
					>login</a> to complete posttest for credit.</p>
				</div>
				<div 
					v-else-if="isError"
					class="w-full text-center p-x2 font-medium text-20px bg-black text-white"
				>
					<p v-html="errorMessage"></p>
				</div>
				<div 
					v-else
					ref="posttestEl"
					class="w-full h-full flex flex-col gap-x1"
				>
					<section class="relative flex-1 overflow-auto">
						<div 
              class="absolute inset-0"
            >
								<div class="relative w-full h-full overflow-y-scroll">
									<div class="w-full h-full flex flex-col gap-x2">
										<div class="flex flex-col gap-x1">
											<p class="font-medium" v-html="qtext"></p>
											<p 
												v-if="subquestion" 
												class="font-medium leading-tight"
												v-html="subquestionText"
											></p>
										</div>
                    <div 
                      v-if="qexplanation && isAEMode"
                      class="p-x1.5 bg-lighter-blue"
                    >
                      <p v-html="qexplanation"></p>
                    </div>
										<div class="relative flex-1">
											<form 
												id="posttestForm"
												@submit.prevent="handleSubmit"
											>
												<div class="flex flex-col gap-x1.5">
													<div
														v-for="(a, a_ndx) in answers"
														:key="a_ndx"
														class="flex flex-row gap-x1 justify-start items-start"
                            :class="{
                              'pointer-events-none': isAEMode && a.iscorrect == 0
                            }"
													>
														<template v-if="selectedChoice">
															<input
																:id="a.ansid"
																class="hidden"
																:name="a.ansid"
																type="radio"
																:value="a.ansid"
																v-model="selectedChoice"
															/>
														</template>
														<template v-else>
															<input
																:id="a.ansid"
																class="hidden"
																:name="a.ansid"
																type="radio"
																:value="a.ansid"
																v-model="selectedChoice"
																required
															/>
														</template>
														<label 
															class="w-16px min-w-16px max-w-16px h-16px min-h-16px max-h-16px bg-gray-003 rounded-full p-3px cursor-pointer"
															:for="a.ansid"
														>
															<div
																v-if="a.ansid === selectedChoice"
																class="w-full h-full bg-orange rounded-full"
															></div>
														</label>
														<label
															class="leading-4 cursor-pointer"
															:class="{
                                'text-green': isAEMode && a.iscorrect == 1,
                                'text-red': isAEMode && a.iscorrect == 0,
																'text-orange': !isAEMode && a.ansid === selectedChoice 
															}"
															:for="a.ansid"
															v-html="a.atext"
														></label>
													</div>
												</div>
											</form>
										</div>
									</div>
							</div>
						</div>
					</section>
					<footer class="w-full flex flex-col gap-x0.5 laptop:gap-x1">
						<div class="progress flex flex-row items-center gap-x2">
							<div class="h-1 flex-1 bg-gray-002 rounded-default overflow-hidden">
								<div 
									class="h-full bg-orange"
									:style="{
										width: percentageWidth
									}"
								></div>
							</div>
							<div class="text-orange text-medium">
								<span>{{ qrank }}</span> / <span>{{ count }}</span>
							</div>
						</div>
            <template v-if="isAEMode">
              <button 
                class="w-full mt-x1 p-x1 laptop:p-x1.5 border-1 border-solid rounded-default text-white text-sm"
                :class="{
                  'bg-black border-black': isChoiceCorrect,
                  'bg-gray-005 border-gray-005 pointer-events-none': !isChoiceCorrect,
                }"
                @click="handleContinue"
                v-html="AESubmitButton"
              ></button>
            </template>
            <template v-else>
              <template v-if="selectedChoice">
                <template v-if="showConfidence">
                  <small class="text-orange text-center">Please submit your confidence below</small>
                  <button 
                    class="w-full p-x1 laptop:p-x1.5 border-1 border-solid border-black rounded-default bg-black text-white text-sm"
                    @click="confidenceLevel = 5"
                    type="submit"
                    form="posttestForm"
                  >Very Confident</button>
                  <button 
                    class="w-full p-x1 laptop:p-x1.5 border-1 border-solid border-black rounded-default bg-black text-white text-sm"
                    @click="confidenceLevel = 3"
                    type="submit"
                    form="posttestForm"
                  >Somewhat Confident</button>
                  <button 
                    class="w-full p-x1 laptop:p-x1.5 border-1 border-solid border-black rounded-default bg-black text-white text-sm"
                    @click="confidenceLevel = 1"
                    type="submit"
                    form="posttestForm"
                  >Not Confident At All</button>
                </template>
                <template
                  v-else
                >
                  <button 
                    class="w-full p-x1 laptop:p-x1.5 border-1 border-solid border-black rounded-default bg-black text-white text-sm"
                    @click="confidenceLevel = -1"
                    type="submit"
                    form="posttestForm"
                  >Submit</button>
                </template>
              </template>
            </template>
					</footer>
				</div>
			</div>
		</template>
	</div>
</template>

<script setup>
import { defineEmits, defineProps, ref, onMounted, computed } from 'vue'
import { LoadingSpinner } from '@/components/'

const emit = defineEmits()

// Props
const props = defineProps({
	jobnum: {
		type: String,
		default: null
	},
	token: {
		type: String,
		default: null
	},
	loginRedirectUrl: {
		type: String,
		default: 'https://suiteweb.atpointofcare.com/#user/login'
	},
	source: {
		type: String,
		default: 'suite-web'
	}
})

// DOM
const posttestEl = ref(null)
const fancyBoxEls = ref([])

// Variables
const posttestStatus = ref(null)
const posttestData = ref(null)
const isLoading = ref(true)
const isError = ref(false)
const errorMessage = ref(null)
const selectedChoice = ref(null)
const confidenceLevel = ref(null)
const fancyBoxImg = ref(null)
const isAEMode = ref(false)
const claimCreditStatus = ref(false)

// Computed
const loginUrl = computed(() => {
	const base = props.loginRedirectUrl
	const params = `redirect=true&redirect_url=${ encodeURIComponent(window.location) }`
	if (base.includes('#')) {
		const split = base.split('#')
		return `${ split[0] }?${ params }#${ split[1] }`
	}
	else {
		return `${ base }?${ params }`
	}
})

const isMissingToken = computed(() => {
  return !props.token || (props.token && (props.token === 'undefined' || props.token === 'null'))
})

const isMissingJobnum = computed(() => {
  return !props.jobnum
})

const nextQid = computed(() => {
	return  posttestStatus.value?.next_qid || qidParam.value
})

const nextSubQid = computed(() => {
	return posttestStatus.value?.next_subqid || subQidParam.value
})

const questions = computed(() => {
	return posttestData.value 
		?.questions?.map(function (question) {
			if (question.type == 40) {
				return question;
			} else if (question.type == 20) {
				return question.subquestions.map(function (subquestion) {
					return {
						qid: question.qid,
						qrank: "".concat(+question.qrank + +subquestion.srank - 1),
						qtext: question.qtext,
						qexplanation: question.qexplanation,
						answers: question.answers,
						subquestion: subquestion,
					};
				});
			}
		})?.flat()
})

const currQuestion = computed(() => {
	if (!nextQid.value) 
	{
		return questions.value?.[0]
	} 
	else if (nextQid.value && nextSubQid.value == 0 || !nextSubQid.value) 
	{
		return questions.value
			.find(function (question) {
				return question.qid == nextQid.value;
			})
	} 
	else if (nextQid.value && nextSubQid.value != 0 || nextSubQid.value) 
	{
		return questions.value
			.find(function (question) {
				return question.qid == nextQid.value && question.subquestion.subqid == nextSubQid.value;
			})
	}
	else return null	
})

const qtext = computed(() => {
  return currQuestion.value?.qtext
})

const qexplanation = computed(() => {
  return currQuestion.value?.qexplanation
})

const subquestion = computed(() => {
  return currQuestion.value?.subquestion
})

const subquestionText = computed(() => {
  return currQuestion.value?.subquestion?.stext
})

const answers = computed(() => {
 return  currQuestion.value?.answers
})

const qrank = computed(() => {
 return currQuestion.value?.qrank
})

const isIdkSelected = computed(() => {
	return !!selectedChoice.value 
		&& currQuestion.value.answers
			.filter((ans) => ans.ansid === selectedChoice.value)[0]
			.atext
			.toLowerCase() === "i do not know"
})

const showConfidence = computed(() => {
	return qType.value == '40' && !isIdkSelected.value
})

const qType = computed(() => {
	return posttestData.value 
		.questions
		.find(function (question, q_ndx) {
			return nextQid.value
				? question.qid == nextQid.value
				: q_ndx === 1;
		})
		?.type
})

const payload = computed(() => {
	return {
		qid: currQuestion.value?.qid,
		subqid: currQuestion.value?.subquestion?.subqid || '',
		ansid: selectedChoice.value,
		confidence: confidenceLevel.value,
		source: getUrlParam('postsrc') || props.source
	}
})

const statusEndpoint = computed(() => {
	return process.env.VUE_APP_POSTTEST_API_URL
})

const claimCreditCheckEndpoint = computed(() => {
	return process.env.VUE_APP_CLAIMCREDITCHECK_API_URL
})

const contentEndpoint = computed(() => {
	return process.env.VUE_APP_TEST_API_URL
})

const qidParam = computed(() => {
 return getUrlParam('postqid') || null
})

const subQidParam = computed(() => {
 return getUrlParam('postsubqid') || ''
})

const ansidParam = computed(() => {
 return getUrlParam('postansid') || null
})

const percentageWidth = computed(() => {
  return qrank.value && questions.value
    ? `${ (qrank.value / questions.value.length) * 100 }%`
    : '0%'
})

const count = computed(() => {
  return questions.value?.length
})

const isChoiceCorrect = computed(() => {
  return selectedChoice.value
    ? currQuestion.value
      ?.answers
      .filter((a) => a.ansid === selectedChoice.value)?.[0]
      ?.iscorrect == 1
    : null
})

const AESubmitButton = computed(() => {
  return isChoiceCorrect.value
    ? 'Continue'
    : 'Please Select the Correct Choice to Continue'
})

// Life Cycle Hooks
onMounted(async () => {
  if (isMissingJobnum.value || isMissingToken.value) {
    isLoading.value = false
    return false
  }
  else {
    await checkClaimCreditStatus()

    if (qidParam.value && ansidParam.value) {
      const paramPayload = {
        qid: qidParam.value,
        subqid: subQidParam.value || '',
        ansid: ansidParam.value,
        confidence: null,
        source: getUrlParam('postsrc') || props.source
      }
      await submitPosttest(paramPayload)
      emit('submit-posttest', {
        jobnum: props.jobnum,
        token: props.token,
        payload: paramPayload,
      })
      clearUrlParams(['postqid', 'postsubqid', 'postansid', 'postsrc'])
    }
    else {
      await checkProgress()
    }

    await getPosttestData()
    initFancyBox()
    isLoading.value = false
  }
})

// Functions
const initFancyBox = () => {
	setTimeout(() => {
		const fancyBoxes = posttestEl.value?.querySelectorAll('.fancybox')
		
		fancyBoxes
			?.forEach((fancyBox) => {
				const img = fancyBox.querySelector('img')
				img.addEventListener('click', (event) => {
					event.preventDefault()
				})

				fancyBoxEls.value.push(fancyBox)
				fancyBox.addEventListener('click', (event) => {
					event.preventDefault()
					showFancyBox(fancyBox)
				})
			})
	}, 500)
}

const showFancyBox = (el) => {
	const href = el.getAttribute('href')

	fancyBoxImg.value = href
}

const handleSubmit = async () => {
	const eventPayload = { 
		...payload.value 
	}
	isLoading.value = true
	await submitPosttest(eventPayload)
	emit('submit-posttest', {
		jobnum: props.jobnum,
		token: props.token,
		payload: eventPayload,
	})
  isLoading.value = false
}

const handleContinue = async () => {
	const eventPayload = { 
		...payload.value 
	}
	isLoading.value = true
	await submitPosttest(eventPayload)
	emit('continue-posttest', {
		jobnum: props.jobnum,
		token: props.token,
	})
  isLoading.value = false
}

const submitPosttest = async (pl) => {
  try { 
    const url = `${ statusEndpoint.value }/${ props.jobnum }?token=${ props.token }`
    const body = JSON
      .stringify(Object.assign(pl, {
        utm_campaign: getUrlParam('utm_campaign') || ''
      }))

    const req = await fetch(url, {
      method: 'POST',
      body
    })
    const res = await req.json()

    if (res?.posttest?.status?.includes('Error')) throw (res?.posttest?.status)
    
    if (isAEMode.value) {
      isAEMode.value = false
      selectedChoice.value = null
      await checkProgress()
    }
    else {
      isAEMode.value = true
      selectedChoice.value = pl.ansid
      confidenceLevel.value = null
    }
  }
  catch (err) {
		isError.value = true
		errorMessage.value = err?.message || err
		isLoading.value = false
  }
}

const  checkClaimCreditStatus = async () => {
  try { 
    const url = `${ claimCreditCheckEndpoint.value }/${ props.jobnum }?token=${ props.token }`
    
    const req = await fetch(url)
    const res = await req.json()
    
    if (res.error) throw (res.error)
    if (!!res.posttest.complete && !!res.eval.complete) emit('credit-claimed')

    claimCreditStatus.value = res
  }
  catch (err) {
		isError.value = true
		errorMessage.value = err?.message || err
		isLoading.value = false
  }
}

const getPosttestData = async () => {
  try { 
    const url = `${ contentEndpoint.value }/${ props.jobnum }?token=${ props.token }`
    
    const req = await fetch(url)
    const res = await req.json()
    
    if (res.error) throw (res.error)

    posttestData.value = res?.posttest
  }
  catch (err) {
		isError.value = true
		errorMessage.value = err?.message || err
		isLoading.value = false
  }
}

const checkProgress = async () => {
  try {
    const url = `${ statusEndpoint.value }/${ props.jobnum }?token=${ props.token }`
    
    const req = await fetch(url)
    const res = await req.json()

    if (res?.posttest?.status.includes('Error')) throw (res?.posttest?.status)
    else if (res?.posttest?.status == 'complete') {
      emit('is-completed', {
        status: 'complete',
        jobnum: props.jobnum,
        token: props.token,
      })
      await submitCompletedClaimCreditStatus()
      throw ('Posttest is complete.')
    }
    
    posttestStatus.value = res?.posttest
  }
  catch (err) {
		isError.value = true
		errorMessage.value = err?.message || err
		isLoading.value = false
  }
}

const submitCompletedClaimCreditStatus = async () => {
  try { 
    const url = `${ claimCreditCheckEndpoint.value }/${ props.jobnum }?token=${ props.token }`

    claimCreditStatus.value.posttest.taken = true
    claimCreditStatus.value.posttest.complete = true
    
    const req = await fetch(url, {
      method: 'PUT',
      body: JSON.stringify(claimCreditCheckEndpoint.value)
    })
    const res = await req.json()
    
    if (res.error) throw (res.error)
  }
  catch (err) {
		isError.value = true
		errorMessage.value = err?.message || err
		isLoading.value = false
  }
}

const getUrlParam = (param) => {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get(param);
}

function clearUrlParams(params) {
  const url = new URL(window.location.href);
  params.forEach(param => url.searchParams.delete(param));
  const newUrl = url.toString();
  window.history.replaceState({}, document.title, newUrl);
}
</script>

<style src="@/assets/css/tailwind.css"></style>
<style src="@/assets/css/_fontface.css"></style>
<style src="@/assets/css/_variables.css"></style>
<style src="@/assets/css/_global.css"></style>
<style src="@/assets/css/_animations.css"></style>
<style src="@/assets/css/main.css"></style>

<style scoped>
.fancy-box { z-index: 9999999999; }
</style>