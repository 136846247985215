import script from "./Faculty.vue?vue&type=script&setup=true&lang=js"
export * from "./Faculty.vue?vue&type=script&setup=true&lang=js"

import "@/assets/css/tailwind.css?vue&type=style&index=0&lang=css&external"
import "@/assets/css/_fontface.css?vue&type=style&index=1&lang=css&external"
import "@/assets/css/_variables.css?vue&type=style&index=2&lang=css&external"
import "@/assets/css/_global.css?vue&type=style&index=3&lang=css&external"
import "@/assets/css/_animations.css?vue&type=style&index=4&lang=css&external"
import "@/assets/css/main.css?vue&type=style&index=5&lang=css&external"

const __exports__ = script;

export default __exports__