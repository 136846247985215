<template>
  <template v-if="isLoading">
    <div class="w-full min-height h-full flex flex-row justify-center items-center">
      <LoadingSpinner
      ></LoadingSpinner>
    </div>
  </template>
  <template v-else>
    <div class="flex flex-col gap-4">
      <header class="flex flex-col gap-4">
        <h1 
          class="text-2xl"
          v-html="header"
        ></h1>
        <p v-html="description"></p>
      </header>
      <section>
        <template v-if="isKnown == 'true'">
          <button
            class="bg-black text-white w-full px-5 py-3 rounded-md"
            @click="handleKnownClick"
          >
            <p>{{ knownLabel }}</p>
          </button>
        </template>
        <template v-else>
          <button
            class="bg-black text-white w-full px-5 py-3 rounded-md"
            @click="handleUnknownClick"
          >
            <p>{{ unknownLabel }}</p>
          </button>
        </template>
      </section>
    </div>
  </template>
</template>

<script setup>
import { computed, defineProps, defineEmits, onMounted, ref } from 'vue'
import { LoadingSpinner } from '@/components/'

const emit = defineEmits([
  'on-load',
  'on-error',
  'on-known-click', 
  'on-unknown-click'
])

// Props
const props = defineProps({
  jobnum: {
    type: String,
    required: true
  },
  isKnown: {
    type: String,
    required: true
  }
})

// Variables
const isLoading = ref(true)
const data = ref(null)

// Computed
const header = computed(() => {
  return data.value?.header
})

const description = computed(() => {
  return data.value?.description
})

const button = computed(() => {
  return data.value?.button
})

const knownLabel = computed(() => {
  return button.value?.label_known
})

const unknownLabel = computed(() => {
  return button.value?.label_unknown
})

// Life Cycle Hooks
onMounted(async () => {
  await handleLoadData()
  addCustomScript()

  emit('on-load')
  isLoading.value = false
})

// Event Hanlders
const handleLoadData = async () => {
  const url = `${ process.env.VUE_APP_USER_ACQUISITION_API_URL }/${ props.jobnum }`

  try {
    const req = await fetch(url)
    const res = await req.json()
    data.value = res
  }
  catch (err) {
    emit('on-error')
  }
}

const handleKnownClick = () => {
  triggerTracking()
  emit('on-known-click')
}

const handleUnknownClick = () => {
  triggerTracking()
  emit('on-unknown-click')
}

// Functions
const addCustomScript = () => {  // First script tag
  const script1 = document.createElement('script')
  script1.type = 'text/javascript'
  script1.text = `
    _linkedin_partner_id = "6222068";
    window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || [];
    window._linkedin_data_partner_ids.push(_linkedin_partner_id);
  `
  document.head.appendChild(script1)

  // Second script tag
  const script2 = document.createElement('script')
  script2.type = 'text/javascript'
  script2.text = `
    (function(l) {
      if (!l) {
        window.lintrk = function(a,b) {
          window.lintrk.q.push([a,b])
        };
        window.lintrk.q = []
      }
      var s = document.getElementsByTagName("script")[0];
      var b = document.createElement("script");
      b.type = "text/javascript";
      b.async = true;
      b.src = "https://snap.licdn.com/li.lms-analytics/insight.min.js";
      s.parentNode.insertBefore(b, s);
    })(window.lintrk);
  `
  document.head.appendChild(script2)

  // Noscript tag
  const noscript = document.createElement('noscript')
  const img = document.createElement('img')
  img.height = 1
  img.width = 1
  img.style.display = 'none'
  img.alt = ''
  img.src = 'https://px.ads.linkedin.com/collect/?pid=6222068&fmt=gif'
  noscript.appendChild(img)
  document.body.appendChild(noscript)
}

const triggerTracking = () => {
  window.lintrk('track', { conversion_id: 16554788 })
}
</script>

<style src="@/assets/css/tailwind.css"></style>
<style src="@/assets/css/_fontface.css"></style>
<style src="@/assets/css/_variables.css"></style>
<style src="@/assets/css/_global.css"></style>
<style src="@/assets/css/_animations.css"></style>
<style src="@/assets/css/main.css"></style>