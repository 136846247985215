<template>
	<div class="pik-widget pik-accordion bg-white p-x2">
		<header v-if="title">
			<div class="border-b-1 border-red pb-x2">
				<h3 class="font-semibold text-16px">{{ title }}</h3>
			</div>
		</header>
		<section 
			class="w-full"
			:class="{
				'border-t-1 border-gray-004': !title
			}"
		>
			<div 
				v-if="isLoading"
				class="py-x4"
			>
				<LoadingSpinner 
					color="light-blue"
					size="100"
					thickness="12"
				/>
			</div>
			<template v-else>
				<template v-if="data">
					<AccordionGroup 
						:data="data" 
						:max-height="maxHeight"
						:expand-type="expandType"
						:default-expand="JSON.parse(defaultExpand)"
					/>
				</template>
				<template v-else>
					Error
				</template>
			</template>
		</section>
	</div>
</template>

<script setup>
import { onMounted, ref, defineProps } from 'vue'

import { LoadingSpinner, AccordionGroup } from '@/components/'

const props = defineProps({
	title: {
		type: String,
		default: null
	},
	type: {
		type: String,
		default: 'static'
	},
	list: {
		type: String,
		default: '[]'
	},
	endpoint: {
		type: String,
		default: ''
	},
	maxHeight: {
		type: String,
		default: null
	},
	expandType: {
		type: String,
		default: 'single'
	},
	defaultExpand: {
		type: String,
		default: '[]'
	},
	options: {
		type: String,
		default: '{}'
	},
	mode: {
		type: String,
		default: null
	}
})

const isLoading = ref(true)
const data = ref(null)

onMounted(async () => {
	if (props.type === 'endpoint') data.value = await getData()
	else data.value = JSON.parse(props.list)

	isLoading.value = false
})

// const endpointOptions = ref(props.options)

const getData = async () => {
	const req = await fetch(props.endpoint)
	const res = await req.json()
	
	if (props.mode === 'test') {
		const output = res.updates.html
			.split('\n\n')
			.map((x) => {
				const pcs = x.split('&nbsp;')
				return {
					title: pcs[0].slice(0,2) 
						+ ' class="flex flex-row gap-x0.5"' 
						+ pcs[0].slice(2)
						+ pcs[1],
					body: pcs[2]
				}
			})

		return output
	}
	else {
		return res
	}
}
</script>

<style src="@/assets/css/tailwind.css"></style>
<style src="@/assets/css/_fontface.css"></style>
<style src="@/assets/css/_variables.css"></style>
<style src="@/assets/css/_global.css"></style>
<style src="@/assets/css/_animations.css"></style>
<style src="@/assets/css/main.css"></style>