<template>
	<div 
		class="pik-widget irae-search p-x2 text-white"
		:style="`background-image: url('${ bgImage }');`"
	>
		<div class="wrapper flex flex-col gap-default">
			<header 
				class="header flex flex-col gap-x2"
			>
				<UiLogo 
					:src="logo"
					max-width="130px"
				/>
				<h3 class="font-medium">{{ title }}</h3>
			</header>
			<section class="content irae-searchbar relative w-full text-12px">
				<form 
					class="flex flex-row items-center h-40px"
					@submit.prevent="submitSearch"
				>
					<div class="h-full flex-1 flex-row flex-nowrap bg-white text-black">
						<input 
							type="text" 
							ref="searchBar"
							class="h-full w-full p-2"
							v-model="query"
							:placeholder="placeholder"
						/>
					</div>
					<div class="h-full">
						<button class="w-80px h-full submit-btn p-2">{{ submitLabel }}</button>
					</div>
				</form>
			</section>
			<section 
				v-if="description"
				class="description flex flex-col gap-default text-10px"
				v-html="description"
			>
			</section>
			<footer
				v-if="funderLine"
				class="footer text-10px text-center"
				v-html="funderLine"
			>
			</footer>
		</div>
	</div>
</template>

<script setup>
import { ref, defineProps, defineEmits } from 'vue'

import { UiLogo } from '@/components/'

const emit = defineEmits(['search-irae'])
const props = defineProps({
	bgImage: {
		type: String,
		default: 'https://suiteweb.atpointofcare.com/img/nccn-banner-background-desktop.png'
	},
	logo: {
		type: String,
		default: 'https://secureapi.atpoc.com/cdn/assets/production/nccn-logo/nccn-banner-@poc-white-logo-w-tm.png'
	},
	title: {
		type: String,
		default: 'Have a Question About Immune-Related Adverse Events?'
	},
	description: {
		type: String,
		default: `<div>
				<p>Answers to your questions are based on the American Society of Clinical Oncology (ASCO) and the NCCN Clinical Practice Guidelines in Oncology (NCCN Guidelines<sup>&reg;</sup>).</p>
				<p>Answers may include one or both of the NCCN and ASCO Guidelines depending on the specificity of your question.</p>
			</div>	
			<div>
				One or the other guideline might be served as the only or the more dominant result based on:
				<ul>
					<li>AI-determined answer appropriateness</li>
					<li>User-generated feedback</li>
					<li>Subject Matter Expert (SME) provided feedback</li>
					<li>Combination of all of the above</li>
				</ul>
			</div>`
	},
	funderLine: {
		type: String,
		default: 'This activity is supported by independent medical education grants from<br><b>Bristol Myers Squibb</b>,<br><b>Merck & Co., Inc.</b>,<br><b>Regeneron Pharmaceuticals, Inc.</b><br>and <b>Sanofi Genzyme</b>.'
	},
	placeholder: {
		type: String,
		default: 'Enter your irAE questions here...'
	},
	submitLabel: {
		type: String,
		default: 'ASK'
	}
})

const query = ref(null)

const submitSearch = () => {
	emit('search-irae', { query: query.value })
	query.value = ''
}
</script>

<style src="@/assets/css/tailwind.css"></style>
<style src="@/assets/css/_fontface.css"></style>
<style src="@/assets/css/_variables.css"></style>
<style src="@/assets/css/_global.css"></style>
<style src="@/assets/css/_animations.css"></style>
<style src="@/assets/css/main.css"></style>

<style scoped>
.irae-search {
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
}
.submit-btn {
	background-color: #f36385;
}
</style>

<style>
.irae-search .description > div,
.irae-search .footer {
	background-color: #0d5890;
	padding: calc(var(--gap) * 2);
}
.irae-search .description p:not(:first-child) {
	margin-top: 1em;
}
.irae-search .description ul,
.irae-search .description ul > li {
	list-style-type: disc;
}
.irae-search .description ul > li {
	margin-top: 0.25em;
	margin-left: 1.5em;
}
</style>