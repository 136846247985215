<template>
	<div
		class="pik-widget ihp-banner overflow-hidden"
		:class="{
			'p-default bg-ihp-gray-002': size !== '180x150' && !(isError && !isBannerError),
			'p-1px rounded-default bg-ihp-primary': size === '180x150' && !(isError && !isBannerError)
		}"
		:style="{
			maxWidth: width,
			maxHeight: height
		}"
	>
		<template v-if="isLoading">
			<div class="min-height w-full flex flex-row justify-center items-center">
				<LoadingSpinner
					color="#4E9D2D"
				></LoadingSpinner>
			</div>
		</template>
		<template v-else-if="isError && !isBannerError">
			<a 
				:href="redirectUrl" 
				:target="redirectTarget"
				class="w-full h-full"
			>
				<div 
					class="w-full"
				>
					<img 
						:src="bannerImage" 
						class="block w-full h-full"
						@error="isBannerError = true"
					/>
				</div>
			</a>
		</template>
		<template v-else-if="isError && isBannerError">
			<div class="min-height w-full flex flex-row justify-center items-center">
				<div class="text-ihp-primary font-semibold text-20px">
					<p>An Error has occured.</p>
					<p>Please try again later.</p>
				</div>
			</div>
		</template>
		<template v-else>
			<a 
				:href="redirectUrl" 
				:target="redirectTarget"
				class="w-full h-full"
			>
				<template v-if="size === '180x150'">
					<div 
						class="wrapper w-full h-full flex flex-col"
					>
						<section class="flex-1 flex flex-col gap-1px w-full rounded-default overflow-hidden">
							<header class="flex flex-row items-start gap-x0.5 bg-white px-x0.5 text-14px">
								<div v-show="isNew">
									<div class="tag tag-small relative bg-ihp-accent text-8px text-center text-white font-bold px-x0.25 pt-x0.25 pb-x0.25">
										<p>New</p>
									</div>
								</div>
								<div class="text-ihp-accent text-8px font-semibold py-x0.25">
									<p v-html="heading"></p>
								</div>
							</header>
							<div class="flex-1 flex flex-col gap-x0.25 px-x0.5 py-x0.25 bg-white">
								<div class="text-8px">
									<p v-html="title"></p>
								</div>
								<div class="flex flex-row items-stretch gap-x0.25">
									<div 
										class="relative"
										:style="{
											width: '45px',
											minWidth: '45px',
											maxWidth: '45px'
										}"
									>
										<img 
											:src="imgSrc" 
											class="w-full h-full object-cover bg-center mx-auto"
										/>
									</div>
									<ul class="ihp-bullet-list flex flex-col gap-x0.25 text-7px">
										<li 
											v-for="(item, item_ndx) in bulletPoints"
											:key="item_ndx"
											class="flex flex-row items-start gap-x0.25 font-normal"
										>
											<p v-html="item"></p>
										</li>
									</ul>
								</div>
								<footer class="w-full flex flex-col">
									<button class="w-full text-center bg-ihp-primary text-white py-x0.25 rounded-default text-10px">
										Start Now
									</button>
								</footer>
							</div>
						</section>
					</div>
				</template>
				<template v-else>
					<div 
						class="wrapper w-full h-full flex flex-col gap-default"
					>
						<section class="flex-1 flex flex-col w-full rounded-default overflow-hidden">
							<header class="flex flex-row items-start gap-default bg-white px-default text-14px">
								<div v-show="isNew">
									<div class="tag relative bg-ihp-accent text-center text-white font-bold px-x0.5 pt-default pb-x2">
										<p>New</p>
									</div>
								</div>
								<div class="text-ihp-accent font-semibold py-default">
									<p v-html="heading"></p>
								</div>
							</header>
							<div class="flex flex-col gap-default px-x2 pt-x2.5 pb-x1.5 bg-gray-002">
								<div class="text-16px">
									<p v-html="title"></p>
								</div>
								<div>
									<img :src="imgSrc" class="w-full"/>
								</div>
							</div>
							<div class="bg-white p-x2 text-12px">
								<ul class="ihp-bullet-list flex flex-col gap-default">
									<li 
										v-for="(item, item_ndx) in bulletPoints"
										:key="item_ndx"
										class="flex flex-row items-start gap-x0.5 font-medium"
									>
										<p v-html="item"></p>
									</li>
								</ul>
							</div>
						</section>
						<footer class="w-full flex flex-col">
							<button class="w-full text-center bg-ihp-primary text-white py-x2 rounded-default">
								Start Now
							</button>
						</footer>
					</div>
				</template>
			</a>
		</template>
</div>
</template>

<script setup>
import { defineProps, ref, onMounted, computed } from 'vue'
import { LoadingSpinner } from '@/components/'
import store from '@/store'

const props = defineProps({
	isNew: {
		type: Boolean,
		default: true
	},
	id: {
		type: String,
		default: null
	},
	endpoint: {
		type: String,
		default: null
	},
	token: {
		type: String,
		default: null
	},
	size: {
		type: String,
		default: '300xauto'
	},
})

const isLoading = ref(true)
const isError = ref(false)
const isBannerError = ref(false)
const defaults = store.getters?.ihpFeed
const data = ref(null)

const width = computed(() => {
	const val = props.size.split('x')[0]
	return val === 'auto'
		? props.size.split('x')[0]
		: props.size.split('x')[0] + 'px'
})

const height = computed(() => {
	const val = props.size.split('x')[1]
	return val === 'auto'
		? props.size.split('x')[1]
		: props.size.split('x')[1] + 'px'
})

const heading = computed(() => {
	return data.value.heading || defaults.heading
})

const title = computed(() => {
	return data.value.title
})

const imgSrc = computed(() => {
	return data.value.img_src
})

const bulletPoints = computed(() => {
	return data.value.bullet_points
})

const redirectUrl = computed(() => {
	return `${
		data.value?.redirect_url
		|| `https://ihp.atpointofcare.com/?jn=${ props.id }`
	}${	
		props.token 
		? `&token=${ props.token }`
		: ''
	}`
})

const redirectTarget = computed(() => {
	return data.value?.redirect_target || '_blank'
})

onMounted(async () => {
	await getData()
	isLoading.value = false
})

const getData = async () => {
	try {
		const req = await fetch(`${ props?.endpoint || defaults?.endpoint }/${ props.id }/banner.json`)
		const res = await req.json()
		const output = res

		data.value = output
	}
	catch (err) {
		isError.value = true
	}
}

const bannerImage = computed(() => {
	return `${ props?.endpoint || defaults?.endpoint }/${ props.id }/assets/${ props.id }_banner_ad.png`
})
</script>

<style src="@/assets/css/tailwind.css"></style>
<style src="@/assets/css/_fontface.css"></style>
<style src="@/assets/css/_variables.css"></style>
<style src="@/assets/css/_global.css"></style>
<style src="@/assets/css/_animations.css"></style>
<style src="@/assets/css/main.css"></style>

<style scoped>
.ihp-banner {
	width: 100%;
}

.min-height {
	min-height: 480px;
}

.tag { width: 50px; }
.tag::before,
.tag::after {
	content: '';
	position: absolute;
	bottom: 0;
	width: 0;
	height: 0;
	border-style: solid;
	transform: translateY(100%);
}
.tag::before {
	left: 0;
	border-width: 25px 25px 0 0;
	border-color: var(--ihp-accent-color) transparent transparent transparent;
}

.tag::after {
	right: 0;
	border-width: 0 25px 25px 0;
	border-color: transparent var(--ihp-accent-color) transparent transparent;
}

.tag.tag-small { width: 26px; }
.tag.tag.tag-small::before { border-width: 8px 13px 0 0;}
.tag.tag.tag-small::after { border-width: 0 13px 8px 0;}

.ihp-bullet-list li::before {
	content: '\2022';
	display: block;
	font-size: 24px;
	line-height: 12px;
	height: 12px;
	color: var(--ihp-primary-color);
}
</style>