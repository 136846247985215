<template>
	<div 
		class="pik-widget cme-information w-full h-full max-h-full"
		:class="[bgClass]"
		:style="[bgStyle]"
	>
		<template v-if="isLoading">
			<div class="w-full h-full flex flex-row justify-center items-center">
				<LoadingSpinner></LoadingSpinner>
			</div>
		</template>
		<template v-else-if="isError">
			<div class="w-full h-full flex flex-row justify-center items-center">
				{{ errorMessage }}
			</div>
		</template>
		<template v-else>
			<div class="relative w-full h-full flex flex-col">
				<div 
					class="relative"
					:class="{
						'flex-1': type === 'fixed',
						'overflow-y-scroll': type === 'auto',
					}"
				>
					<div 
						:class="{
							'absolute inset-0': type === 'fixed',
							'relative': type === 'auto',
						}"
					>
						<div class="relative w-full h-full overflow-y-scroll">
							<div class="px-x1 pb-x4 text-sm">
								<section
									v-for="(section, section_ndx) in contentSections"
									:key="section_ndx"
									class="flex flex-col gap-x1 py-x2 border-b-1 border-solid border-gray-004"
								>
									<header v-if="section.title">
										<h3 
											v-html="section.title"
											class="font-medium"
										></h3>
									</header>
									<div 
										v-if="section.content && section.content.length > 0"
										class="flex flex-col gap-x1"
									>
										<section
											v-for="(content, content_ndx) in section.content"
											:key="content_ndx"
										>
											<template v-if="content.type === 'html'">
												<div 
													class="flex flex-col gap-x1.5"
													v-html="content.content"
												></div>
											</template>
											<template v-if="content.type === 'image-list'">
												<ImageList :images="content.content" />
											</template>
											<template v-if="content.type === 'faculty-list'">
												<FacultyList :faculty="content.content" />
											</template>
										</section>
									</div>
								</section>
								<footer class="flex flex-col gap-x1 pt-x2">
									<div 
										v-if="trademark"
										class="flex flex-col gap-x1"
										v-html="trademark"
									></div>
									<div>
										<span>{{ jobnum }}</span>
									</div>
								</footer>
							</div>
						</div>
					</div>
				</div>
			</div>
		</template>
	</div>
</template>

<script setup>
import { defineProps, ref, onMounted, computed } from 'vue'
import store from '@/store'
import { 
	LoadingSpinner,
	ImageList,
	FacultyList,
} from '@/components/'

// Props
const props = defineProps({
	type: {
		type: String,
		default: 'auto'
	},
	jobnum: {
		type: String,
		required: true,
	},
	bgColor: {
		type: String,
		default: 'white'
	},
	hasClose: {
		type: Boolean,
		default: true
	},
	handleClose: {
		type: Function,
		default: () => { console.log('close') }
	},
})

// DOM

// Variables
const isLoading = ref(true)
const isError = ref(false)
const errorMessage = ref(null)
const contentData = ref(null)

// Computed
const isBgColorHash = computed(() => {
	return props.bgColor
		.includes('[')
})

const bgClass = computed(() => {
	return !isBgColorHash.value
		? `bg-${ props.bgColor }`
		: ''
})

const bgStyle = computed(() => {
	return isBgColorHash.value
		? {
			backgroundColor: props.bgColor
				.substring(1, props.bgColor.length - 1)
		}
		: {}
})

const cmeData = computed(() => {
	return contentData.value?.cme
})

const contentSections = computed(() => {
	const output =[]

	// Funder Line
	output.push({
		content: [
			{
				type: 'image-list',
				content: cmeData.value?.funderLogos?.peerReviewed
			},
			{
				type: 'html',
				content: cmeData.value?.funderInfo[0]?.funderHTML
			}
		]
	})
	// Faculty
	if (cmeData.value?.faculty && cmeData.value?.faculty.length > 0) {
		output.push({
			title: 'Faculty',
			content: [
				{
					type: 'faculty-list',
					content: cmeData.value?.faculty
				}
			]
		})
	}
	// Available Dates
	if (cmeData.value?.estimatedTimeToComplete && cmeData.value?.estimatedTimeToComplete.length > 0) {
		output.push({
			content: [
				{
					type: 'html',
					content: cmeData.value?.estimatedTimeToComplete
						.map((activity) => {
							return `<div>
								<span>
									<strong>Release Date: </strong> ${activity.cme_publishDate} 
									<br />
								</span>
								<span>
									<strong>Termination Date: </strong> ${activity.cme_terminationDate}  
									<br />
								</span>
							</div>`;
						})
						.join('\n')
				}
			]
		})
	}
	// Credits Available
	if (cmeData.value?.credits_available && cmeData.value?.credits_available.length > 0) {
		output.push({
			title: 'Credit Available',
			content: [
				{
					type: 'html',
					content: `<div>
						${
							cmeData.value?.credits_available
								.map(credit => {
									return credit.actualCredit
										? `<span>
											${credit.creditType}: ${credit.actualCredit} 
											<br />
										</span>`
										: '';
								})
								.join('\n')
						}
					</div>`
				}
			]
		})
	}
	// Estimated Time
	output.push({
		title: 'Estimated time for completion of this activity',
		content: [
			{
				type: 'html',
				content: `<div>
					${
						cmeData.value?.credits
							? cmeData.value?.credits
									.map(credit => `<span>
											${credit.creditType}: ${credit.actualCredit} 
											<br />
										</span>`,
									)
									.join('\n')
							: ''
					}
					${
						cmeData.value?.abim_credit
						? `<p>${cmeData.value?.abim_credit}</p>`
						: ''
					}
				</div>`
			}
		]
	})
	// Instructions
	if (cmeData.value?.cmeInstructions && cmeData.value?.cmeInstructions.length > 0) {
		output.push({
			content: [
				{
					type: 'html',
					content: cmeData.value?.cmeInstructions
						.map((instruction) => {
							return `<div 
								class="flex flex-col gap-x0.5"
							>
								${
									instruction.title
									? `<div>
											<h3 class="font-medium">${instruction.title}</h3>
										</div>`
									: ''
								}
								<div class="flex flex-col gap-x1.5">
									${instruction.cmeInstructions}
									${instruction.noFeeDisclaimer}
								</div>
							</div>`;
						})
						.join('\n')
				}
			]
		})
	}
	// Contact Info
	if (cmeData.value?.contactInfo) {
		output.push({
			content: [
				{
					type: 'html',
					content: cmeData.value?.contactInfo
				}
			]
		})
	}
	// Target Audience
	if (cmeData.value?.targetAudience) {
		output.push({
			title: 'Target Audience',
			content: [
				{
					type: 'html',
					content: cmeData.value?.targetAudience
				}
			]
		})
	}
	// Activity Goal
	if (cmeData.value?.activityGoal) {
		output.push({
			title: 'Activity Goal',
			content: [
				{
					type: 'html',
					content: cmeData.value?.activityGoal
				}
			]
		})
	}
	// Learning Objectives
	if (cmeData.value?.learningObjectives && cmeData.value?.learningObjectives.length > 0) {
		output.push({
			title: 'Learning Objective(s)',
			content: [
				{
					type: 'html',
					content: `<ul>${
						cmeData.value?.learningObjectives
							.map(objective => {
								return `<li>${objective.objective}</li>`;
							})
							.join('\n')
					}</ul>`
				}
			]
		})
	}
	// CME Info
	if (cmeData.value?.cmeInfo && cmeData.value?.cmeInfo.length > 0) {
		output.push({
			content: [
				{
					type: 'html',
					content: cmeData.value?.cmeInfo
						.map((info) => {
							return `<div 
								class="flex flex-col gap-x0.5"
							>
								${
									info.title
									? `<div>
											<h3 class="font-medium">${info.title}</h3>
										</div>`
									: ''
								}
								<div class="flex flex-col gap-x1.5">
									${
										!info.statementOfAccreditation
											? ''
											: `<p>${info.statementOfAccreditation}</p>`
									}
									<p>${info.creditDesignation}</p>
								</div>
							</div>`;
						})
						.join('\n')
				}
			]
		})
	}
	// Disclosure Info
	output.push({
		title: 'Disclosure Information',
		content: [
			{
				type: 'html',
				content: `<div class="flex flex-col gap-x1.5">
					${
						cmeData.value?.disclosureInformation
						? `<p>${ cmeData.value?.disclosureInformation }</p>`
						: ''
					}
					<!-- Faculty Disclosures -->
					${
						cmeData.value?.facultyDisclosures
							? cmeData.value?.facultyDisclosures.map(disclosure => {
									return `<p>
							<strong>${disclosure.fullName}</strong>
							${disclosure.disclosure}
						</p>`;
								})
							: ''
					}
					<!-- CE Disclosures -->
					${
						cmeData.value?.ceDisclosures
							? cmeData.value?.ceDisclosures.map(disclosure => {
									return `<p>
							<strong>${disclosure.fullName}</strong>
							${disclosure.disclosure}
						</p>`;
								})
							: ''
					}
					${
						cmeData.value?.medicalWriters
							? cmeData.value?.medicalWriters.map(writer => {
									return `<p>
							<strong>${writer.fullName}</strong>
							${writer.disclosure}
						</p>`;
								})
							: ''
					}
					${
						cmeData.value?.conflictsOfInterest 
						? cmeData.value?.conflictsOfInterest 
						: ''
					}
				</div>`
			}
		]
	})
	// Mutual Responsibility
	if (cmeData.value?.mutualResponsibility) {
		output.push({
			content: [
				{
					type: 'html',
					content: cmeData.value?.mutualResponsibility
				}
			]
		})
	}

	return output
})

const trademark = computed(() => {
	return cmeData.value?.trademark
})
// Life Cycle Hooks
onMounted(async () => {
	if (!props.jobnum) {
		isError.value = true
		errorMessage.value = 'Missing Job Number'
	}
	else {
		try {
			contentData.value = await store.dispatch('CRUD', {
				method: 'GET',
				url: `${ process.env.VUE_APP_CONTENT_API_URL }/${ props.jobnum }`
			})
		}
		catch (err) {
			isError.value = true
			errorMessage.value = err
		}
	}

	setTimeout(() => {
		isLoading.value = false
	}, 1000)
})
</script>

<style src="@/assets/css/tailwind.css"></style>
<style src="@/assets/css/_fontface.css"></style>
<style src="@/assets/css/_variables.css"></style>
<style src="@/assets/css/_global.css"></style>
<style src="@/assets/css/_animations.css"></style>
<style src="@/assets/css/main.css"></style>

<style>
.cme-information h4 {
	font-size: var(--h3-font-size);
	font-weight: 500;
}

.cme-information ul { padding-left: 20px; }

.cme-information ul,
.cme-information li {
	list-style: disc;
}
</style>