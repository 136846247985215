<template>
	<div 
		ref="componentEl"
		class="pik-widget universal-chatbot"
		:style="{
			width: width,
			height: height,
			backgroundColor: '#6B6EA3'
		}"
	>
		<template v-if="isLoading">
			<div class="w-full h-full flex flex-row justify-center items-center">
				<LoadingSpinner
					color="#5C5C5C"
					size="80"
					thickness="8"
				></LoadingSpinner>
			</div>
		</template>
		<template v-else-if="isError">
			<div class="w-full h-full flex flex-row justify-center items-center text-white">
				{{ errorMessage }}
			</div>
		</template>
		<template v-else>
			<div class="chatbot-container relative w-full h-full theme--default">
				<div class="chatbot relative w-full h-full">
					<div class="chatbot-content">
						<header class="chatbot-header chatbot-section">
							<div class="container">
								<h1 class="chatbot-title" v-html="title"></h1>
								<h3 class="chatbot-description" v-html="funder_line"></h3>
							</div>
						</header>
						<div class="chatbot-body chatbot-section">
							<div class="container">
								<div class="chatbot-response-frame">
									<div class="chatbot-response-area">
										<div 
											ref="responsesEl"
											class="chatbot-responses text-xs"
										>
											<div
												ref="responseEls"
												v-for="(item, item_ndx) in conversation"
												:key="item_ndx"
												class="chatbot-response"
											>
											<template v-if="item.question">
												<div 
													ref="questionEls"
													class="chatbot-response-question chatbot-response-section"
												>
														<div class="chatbot-response-bubble">
															<div class="chatbot-response-bubble-content">
																<div class="chatbot-response-bubble-content-card dropshadow">
														<div v-html="item.question.content"></div>
													</div>
															</div>
															<div class="chatbot-response-bubble-tail"><span>▼</span></div>
														</div>
														<div class="chatbot-response-footer">
															<div class="chatbot-response-usericon dropshadow">
																<img src="https://cdn.atpoc.com/cdn/assets/production/chatbots/cll-person-icon.png">
															</div>
															<div class="chatbot-response-toolbar">
																<div class="chatbot-response-buttons">
																	
																	
																</div>
																
															</div>
														</div>
													</div>
												</template>
												<template v-if="item.answer">
													<div 
														ref="answerEls" 
														class="chatbot-response-answer chatbot-response-section"
													>
														<div class="chatbot-response-bubble">
															<div class="chatbot-response-bubble-content chatbot-response-bubble-content-card dropshadow">
																<template v-if="item.answer.suggested">
																	<p v-html="item.answer.suggested.pre_text"></p>
																	<ul 
																		v-if="item.answer.suggested.suggested_list && item.answer.suggested.suggested_list?.length > 0"
																		class="chatbot-response-suggested"
																	>
																		<li 
																			v-for="(suggestion, suggestion_ndx) in item.answer.suggested.suggested_list"
																			:key="suggestion_ndx"
																			class="ask-suggested" 
																			@click="handleSuggestion({
																				query_text: suggestion,
																				id_autosuggest: item.answer.suggested.id_autosuggest,
																			})"
																		>{{ suggestion }}</li>
																	</ul>
																	<p v-html="item.answer.suggested.post_text"></p>
																</template>
																<template v-else>
																	<div v-html="item.answer.content"></div>
																</template>
															</div>
															<div class="chatbot-response-bubble-tail"><span>&#9660;</span></div>
														</div>
														<div class="chatbot-response-footer">
															<div class="chatbot-response-usericon dropshadow">
																<img src="https://cdn.atpoc.com/cdn/assets/production/poc/@-logo-only/@-only.png">
															</div>
															<div class="chatbot-response-toolbar">
																<div class="chatbot-response-buttons">
																	<template v-if="item.answer.annotation">
																		<button 
																			class="chatbot-response-button chatbot-response-button-blue dropshadow toggle-accordion" 
																			@click="toggleReference(item.index)"
																		>References</button>
																	</template>
																	<template v-if="item.answer.earn_credit">
																		<a 
																			class="earn-cme-btn chatbot-response-button chatbot-response-button-orange dropshadow" 
																			:href="item.earn_credit_url || defaultEarnCreditUrl" 
																			target="_blank"
																		>Earn Credit</a>
																	</template>
																</div>
																<template 
																	v-if="item.answer.annotation && item.is_reference_visible"
																>
																	<div class="chatbot-accordion dropshadow">
																		<div class="chatbot-response-annotation">
																			<div v-html="item.answer.annotation"></div>
																		</div>
																	</div>
																</template>
															</div>
														</div>
													</div>
												</template>
												<template v-else>
													<div class="chatbot-response-loader chatbot-response-section">
														<div class="chatbot-response-bubble">
															<div class="chatbot-response-bubble-content chatbot-response-bubble-content-card dropshadow">
																<div class="load-indicator flex flex-row gap-1">
																	<div class="load-indicator-circle"></div>
																	<div class="load-indicator-circle"></div>
																	<div class="load-indicator-circle"></div>
																</div>
															</div>
															<div class="chatbot-response-bubble-tail"><span>&#9660;</span></div>
														</div>
														<div class="chatbot-response-footer">
															<div class="chatbot-response-usericon dropshadow">
																<img src="https://cdn.atpoc.com/cdn/assets/production/poc/@-logo-only/@-only.png">
															</div>
														</div>
													</div>
												</template>
											</div>
										</div>
									</div>
									<div class="chatbot-message">
										<span>Scroll down to continue reading</span>
									</div>
								</div>
							</div>
						</div>
						<footer class="chatbot-footer chatbot-section">
							<div class="container">
								<form 
									class="chatbot-searchbar ask-chatbot"
									:class="[
										{
											disabled: isSearchDisabled
										}
									]"
									@submit.prevent="handleSubmit"
								>
									<div class="chatbot-searchbar-field text-xs">
										<textarea 
											ref="textAreaEl"
											v-model="question"
											class="chatbot-searchbar-input text-black" 
											name="query_text" 
											type="text" 
											:placeholder="placeholder" 
											rows="1" 
											required
											@input="handleTextAreaInput" 
											@change="handleTextAreaChange" 
											@keydown="handleTextAreaKeydown" 
											@keyup="handleTextAreaKeyup" 
											@paste="handleTextAreaPaste"
											@cut="handleTextAreaCut"
											@keydown.enter.prevent="handleSubmit"
										></textarea>
									</div>
									<button class="chatbot-searchbar-submit flex flex-row justify-center items-center">
										<img src="https://cdn.atpoc.com/cdn/assets/production/chatbots/submit-ask-logo.png" alt="ASK">
									</button>
								</form>
								<div class="chatbot-footnote">
									<span>Intended for US healthcare professionals</span>
								</div>
							</div>
						</footer>
						<div 
							v-if="isAutocompleteVisible && autocompleteSuggestions && autocompleteSuggestions.length > 0"
							class="chatbot-autocomplete z-50 absolute autocomplete text-black transform -translate-y-full text-xs"
							:style="{
								top: caretPosition.top || 0,
								left: caretPosition.left || 0
							}"
						>
							<div class="flex flex-col w-full h-full overflow-y-scroll">
								<button
									v-for="(ac, ac_ndx) in autocompleteSuggestions"
									:key="ac_ndx"
									class="autocomplete-item fill-autocomplete text-left px-2 py-1"
									:class="{
										'border-b-1 border-solid border-gray-004': ac_ndx < (autocompleteSuggestions.length - 1)
									}"
									@click="handleAutocompleteSelect(ac)"
								>{{ ac }}</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</template>
	</div>
</template>

<script setup>
import { defineProps, defineExpose, ref, onMounted, computed } from 'vue'
import { LoadingSpinner } from '@/components/'
import store from '@/store'
import { stringifyQuery } from 'vue-router'
import getCaretCoordinates from 'textarea-caret'

// Props
const props = defineProps({
	width: {
		type: String,
		default: '300px'
	},
	height: {
		type: String,
		default: '600px'
	},
	name: {
		type: String,
		required: true
	},
	endpointVers: {
		type: String,
		default: 'prod'
	},
	token: {
		type: String,
		default: null
	},
	question: {
		type: String,
		default: null
	},
	greeting: {
		type: Boolean,
		default: true
	},
	autocomplete: {
		type: Boolean,
		default: true
	},
	earnCredits: {
		type: Boolean,
		default: true
	},
	typeEffect: {
		type: Boolean,
		default: true
	},
	typeSpeed: {
		type: Number,
		default: 100
	},
	placeholder: {
		type: stringifyQuery,
		default: 'Type in your question here...'
	}
})

// DOM
const componentEl = ref(null)
const responsesEl = ref(null)
const responseEls = ref(null)
const questionEls = ref(null)
const answerEls = ref(null)
const textAreaEl = ref(null)

// Variables
const isLoading = ref(true)
const isError = ref(false)
const errorMessage = ref(null)

const copy = ref(null)
const isSearchDisabled = ref(true)
const placeholder = ref(props.placeholder)
const conversation = ref([])
const question = ref('')
const caretPosition = ref({})
const currentTypePosition = ref(0)
const termStart = ref(0)
const termEnd = ref(0)
const isAutocompleteVisible = ref(false)
const stopAutocomplete = ref(true)
const autocompleteSuggestions = ref(null)
const currentTerm = ref('')

// Computed
const copyEndpoint = computed(() => {
	return store.getters.chatbot.copy[props.endpointVers]
})

const routerEndpoint = computed(() => {
	return store.getters.chatbot.router[props.endpointVers]
})

const title = computed(() => {
	return copy.value?.chatbot_title
})

const funder_line = computed(() => {
	return copy.value?.funder_line
})

const count = computed(() => {
	return conversation.value?.length
})

const defaultEarnCreditUrl = computed(() => {
	return 'https://google.com'
})

// Life Cycle Hooks
onMounted(async () => {
	if (!props.name) {
		isError.value = true
		errorMessage.value = 'Missing TA Name'
	}
	else {
		await getContent()
		isSearchDisabled.value = false
	}

	isLoading.value = false

	if (!isError.value && !isLoading.value) {
		if (props.typeEffect) setInterval(handlePlaceholderTyping, props.typeSpeed)
		else placeholder.value = props.placeholder
	}

	if (props.question) handleSubmit({ query_text: props.question })
})

// Functions
const handleAutocompleteSelect = (keyword) => {
	replaceCurrentTerm(keyword)
}

const handleSuggestion = (props) => {
	handleSubmit(props)
}

const handleSubmit = async (args) => {
	const index = conversation.value.length

	conversation.value.push({
		index,
		is_reference_visible: false,
		question: {
			content: args.query_text || question.value
		}
	})

	setTimeout(async() => {
		await scrollToTop(responseEls.value, 'question')
	}, 100)

	setTimeout(async() => {
		const answer = await getAnswer({
			query_text: question.value,
			...args
		})

		conversation.value[index].answer = {
			content: answer?.answer_text_raw,
			suggested: answer?.suggested,
			annotation: answer?.annotation || null,
			earn_credit: answer?.river === 'amazon - high_confidence',
			earn_credit_url: answer?.survey_link || null,
		}

		await scrollToTop(responseEls.value, 'answer')
	}, 500)
}

const handleSearchbarHeight = () => {
  const element = textAreaEl.value;
	
  if (element) {
    element.style.height = 'auto'; // Reset height to allow it to shrink/grow
    const maxHeight = parseInt(getComputedStyle(element).lineHeight, 10) * 3; // Calculate max height for 3 lines
    element.style.height = `${Math.min(element.scrollHeight, maxHeight)}px`; // Set height to scrollHeight or maxHeight, whichever is smaller
    if (element.scrollHeight > maxHeight) {
      element.style.overflowY = 'auto'; // Enable scroll for content beyond 3 lines
    } else {
      element.style.overflowY = 'hidden'; // Hide scrollbar when content fits within 3 lines
    }
  }
}

const handleTextAreaInput = () => {
	handleSearchbarHeight()
	if (props.autocomplete) getAutocomplete()
} 

const handleTextAreaChange = () => {
	handleSearchbarHeight()
} 

const handleTextAreaKeydown = () => {
	handleSearchbarHeight()
} 

const handleTextAreaKeyup = () => {
	handleSearchbarHeight()
} 

const handleTextAreaPaste = () => {
	handleSearchbarHeight()
}

const handleTextAreaCut = () => {
	handleSearchbarHeight()
}

const replaceCurrentTerm = (replacementText) => {
	const beforeTerm = question.value.substring(0, termStart.value);
	const afterTerm = question.value.substring(termEnd.value);

	// Construct new value
	question.value = `${beforeTerm}${replacementText}${afterTerm}`;

	// Calculate new caret position
	const newCaretPosition = beforeTerm.length + replacementText.length;
	textAreaEl.value.focus()
	textAreaEl.value.setSelectionRange(newCaretPosition, newCaretPosition);

	// Update current term after replacement
	disableAutocomplete()
	updateCurrentTerm();
}

const enableAutocomplete = () => {
	stopAutocomplete.value = false
}

const disableAutocomplete = () => {
	stopAutocomplete.value = true
	isAutocompleteVisible.value = false
	autocompleteSuggestions.value = null
}

const updateCurrentTerm = () => {
	const caretPos = textAreaEl.value.selectionStart;
	const textValue = question.value;

	// Find the start of the term
	termStart.value = caretPos;
	while (termStart.value > 0 && !/\s/.test(textValue[termStart.value - 1])) {
		termStart.value--;
	}

	// Find the end of the term
	termEnd.value = caretPos;
	while (termEnd.value < textValue.length && !/\s/.test(textValue[termEnd.value])) {
		termEnd.value++;
	}

	// Extract the current term
	const currentWord = textValue.substring(termStart.value, termEnd.value);

	currentTerm.value = currentWord || null
}

const getAutocomplete = async () => {
	updateCaretPosition()
	updateCurrentTerm()

	if (currentTerm.value) enableAutocomplete()	
	else disableAutocomplete()

	if (!stopAutocomplete.value) {
		const autocompleteData = await store.dispatch('CRUD', {
			method: 'GET',
			url: `${
				store.getters.chatbot.autocomplete
			}?term=${
				currentTerm.value
			}&chatbot=${
				props.name
			}`,
		})

		// check for valid suggestions list
		if (!autocompleteData.suggestions?.length) {
			console.log('%cautocomplete: no suggestions','color: orange;')
			disableAutocomplete()
			return
		}
		// if only one suggestion returned
		if (autocompleteData.suggestions?.length == 1 
			&& autocompleteData.suggestions[0] == currentTerm.value) {
			disableAutocomplete()
			return
		}

		isAutocompleteVisible.value = true
		autocompleteSuggestions.value = autocompleteData?.suggestions
	}
}

const updateCaretPosition = () => {
	const wrapper = componentEl.value
	const wrapperRect = wrapper.getBoundingClientRect(); // Get the wrapper's rect

	const position = getCaretCoordinates(textAreaEl.value, textAreaEl.value.selectionEnd);
	const textareaRect = textAreaEl.value.getBoundingClientRect();

	// Calculate the caret's position relative to the wrapper.
	// Subtract the wrapper's offsets to adjust for its position on the page.
	const top = textareaRect.top - wrapperRect.top + window.scrollY + position.top - 5; // Adjust 5px to account for the cursor's height
	const left = textareaRect.left - wrapperRect.left + window.scrollX + position.left;

	// Apply the calculated position to the floating div.
	caretPosition.value.top = `${top}px`;
	caretPosition.value.left = `${left}px`;
}

const handlePlaceholderTyping = () => {
  placeholder.value = props.placeholder.substring(0, currentTypePosition.value)

  if (currentTypePosition.value < props.placeholder.length) currentTypePosition.value += 1
	else currentTypePosition.value = 0
}

const scrollToTop = async (els, type) => {
	let scrollPosition
	const container = responsesEl.value

	if (type === 'answer' && els && els.length > 0) {
		scrollPosition = els
			.map((el) => {
				return el.clientHeight
			})
			.reduce((acc, curr) => parseInt(acc) + parseInt(curr), 0)
			- 100
	}
	else {
		scrollPosition = container.scrollHeight
	}

	setTimeout(() => {
		container
			.scrollTo({
				top: scrollPosition, // Vertical position
				behavior: 'smooth' // Smooth scroll
			})
	}, 100)
}

const toggleReference = (index) => {
	conversation.value[index].is_reference_visible = !conversation.value[index].is_reference_visible

	if (conversation.value[index].is_reference_visible) {
		setTimeout(() => {
			responsesEl.value
				.scrollBy({
					top: 100, // Vertical position
					behavior: 'smooth' // Smooth scroll
				})
		}, 100)
	}
}

const getAnswer = async (args) => {
	const min = Math.ceil(0)
	const max = Math.floor(100000000)
	const uid = Math.floor(Math.random() * (max - min + 1)) + min
	
	const payload = {
		...args,
		chatbot_name: props.name,
		banner_type: uid,
		location: window.location.href,
		mode: 'original_voice_router',
		response_count: count.value,
		user_agent: navigator.userAgent,
		token: props.token
	}

	return store.dispatch('CRUD', {
		method: 'POST',
		url: routerEndpoint.value,
		payload
	})
}

const getContent = async () => {
	try {
		copy.value = await store.dispatch('CRUD', {
			method: 'GET',
			url: `${ copyEndpoint.value }?chatbot_name=${ props.name }`
		})
	}
	catch (err) {
		isError.value = true
		errorMessage.value = err
	}
}

// Expose
defineExpose({
	handleSubmit
})
</script>

<style src="@/assets/css/tailwind.css"></style>
<style src="@/assets/css/_fontface.css"></style>
<style src="@/assets/css/_variables.css"></style>
<style src="@/assets/css/_global.css"></style>
<style src="@/assets/css/_animations.css"></style>
<style src="@/assets/css/main.css"></style>

<style>
:root {
	/* COLORS */
	--color-white: #FFFFFF;
	--color-gray: #5C5C5C;
	--color-black: #000000;
	--color-blue: #6B6EA3;

	/* THEME */
	--primary-text-color: var(--color-white);
	--primary-bg-color: var(--color-blue);
	--secondary-text-color: var(--color-gray);
	--secondary-bg-color: var(--color-white);
}

.universal-chatbot * {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

.universal-chatbot .full-width {
	position: relative;
	width: 100%;
	height: 100%;
}

.universal-chatbot .content-center {
	display: flex;
	justify-content: center;
	align-items: center;
}

.universal-chatbot .chatbot-error { text-align: center; }

.universal-chatbot .no-cme .earn-cme-btn { display: none; }

.chatbot-loader { color: #5C5C5C !important; }
.chatbot-error { color: #FFFFFF !important; }

.chatbot {
	padding: 0 8px !important;
	background: #6B6EA3 !important;
	color: #FFFFFF !important;
	font-family: Helvetica Neue, serif !important;
	font-size: 16px !important;
	overflow: visible !important;
}

/* Modules */
.container {
	padding: 0 !important;
	padding-left: 0 !important;
	padding-right: 0 !important;
	padding-top: 0 !important;
	padding-bottom: 0 !important;
	margin: 0 !important;
	margin-left: 0 !important;
	margin-right: 0 !important;
	margin-top: 0 !important;
	margin-bottom: 0 !important;
	position:  relative !important;
	width: 100% !important;
	min-width: 100% !important;
	max-width: 100% !important;
	height: 100% !important;
	font-size: 1em !important;
	overflow: hidden !important;
	box-sizing: border-box !important;
}

.dropshadow { box-shadow: 1px 1px 6px rgba(0,0,0,0.29); }

.chatbot-content {
	display: flex;
	flex-flow: column nowrap;
	width: 100%;
	height: 100%;
}

.chatbot-section {
	width: 100% !important;
	overflow: hidden !important;
}

/* Header  */
.chatbot-header {
	padding: 5px 0 8px 0;
	color: #ffffff;
}

.chatbot-header .chatbot-title {
	margin: 0 0 0.375em 0 !important;
	padding-bottom: 0.375em !important;
	font-size: 1.125em !important;
	font-weight: 500 !important;
	border-bottom: 1px solid #ffffff !important;
	color: inherit !important;
}

.chatbot-header .chatbot-description {
	margin: 0 !important;
	font-size: 0.5625em !important;
	font-weight: 300 !important;
	color: inherit !important;
}

.chatbot-header .chatbot-description strong {
	font-weight: 400;
}

/* Body */
.chatbot-body {
	flex: 1;
	background: #ffffff;
	border-radius: 5px;
}

/* Footer */
.chatbot-footer {
	min-height: 60px;
	padding: 8px 0 5px 0;
	color: #ffffff;
}

/* Searchbar */
.chatbot-searchbar {
	display: flex;
	flex-flow: row nowrap;
	justify-content: center;
	align-items: flex-end;
	margin-bottom: 0.375em;
	font-size: 0.875em !important;
}

.chatbot-searchbar.disabled { visibility: visible !important; }
.chatbot-searchbar.disabled textarea { visibility: hidden !important; }
.chatbot-searchbar.disabled button { pointer-events: none !important; } 

.chatbot-searchbar > * { 
	width: 100%;
	background: #ffffff;
	border-radius: 5px;
	border: 0;
	outline: 0;
	box-sizing: border-box;
}

.chatbot-searchbar-field {
	position: relative;
	padding: 4px 12px;
	min-height: 32px;
}

.chatbot-searchbar-input {
	display: block;
	width: 100%;
	height: auto;
	min-height: 24px;
	max-height: 60px;
	line-height: 20px;
	font-family: inherit;
	border: 0;
	outline: 0;
	resize: none !important;
	overflow: auto;
}

.chatbot-searchbar-input::placeholder {
	line-height: inherit;
	font-weight: 400;
	opacity: 0.8;
}

.chatbot-searchbar-submit {
	margin-left: 8px;
	padding: 5px;
	min-width: 60px !important;
	width: 60px !important;
	height: 32px;
}

.chatbot-searchbar-submit > img {
	max-width: 100%;
	max-height: 100%;
}

/* Footnote */
.chatbot-footnote {
	font-size: 0.625em !important;
	font-weight: 300;
}

.chatbot-footnote > * { display: block; }

/* Response Frame */
.chatbot-response-frame {
	display: flex;
	flex-flow: column nowrap;
	width: 100%;
	height: 100%;
	color: #5C5C5C !important;
}

.chatbot-response-area {
	flex: 1;
	display: flex;
	justify-content: flex-end;
	flex-flow: column nowrap;
	position: relative;
	padding: 0.625em 0;
	font-size: 0.75em !important;
	overflow: hidden;
}

.chatbot-responses {
	width: 100%;
	min-width: 100%;
	max-width: 100%;
	max-height: 100%;
	padding: 0.5em 0.625em;
	overflow-y: scroll;
}

/* Response */
.chatbot-response {
	display: block;
	position: relative;
}

.chatbot-response:not(:last-child) {
	margin-bottom:  10px;
}

.chatbot-response-section {
	display: flex;
	flex-flow: column nowrap;
	justify-content: flex-start;
	margin-bottom: 0.75em;
}

.chatbot-response-question { align-items: flex-end; padding-left: 36px; }
.chatbot-response-answer,
.chatbot-response-loader { align-items: flex-start; padding-right: 36px; }

.chatbot-response-bubble { 
	position: relative;
	max-width: calc(100% - 32px);
}

.chatbot-response-question .chatbot-response-bubble { margin-right: 32px; }
.chatbot-response-answer .chatbot-response-bubble,
.chatbot-response-loader .chatbot-response-bubble { margin-left: 32px; }

.chatbot-response-bubble-content { 
	position: relative;
}

.chatbot-response-bubble-content strong { font-weight: 500; }

.chatbot-response-bubble-content ul {
	margin: 0.75em 0 !important;
	padding-left: 1em !important;
	list-style-type: none;
}

.chatbot-response-bubble-content ul > li {
	position: relative;
	margin-bottom: 0.5em;
}

.chatbot-response-bubble-content ul > li::before {
	content: '\2022 ';
	display: block;
	position: absolute;
	margin-left: -1em;
	top: 0;
	left: 0;
}

.chatbot-response-bubble-content-card { 
	margin-bottom: 0.5em;
	padding: 1em 1.5em;
	background: #F7F7F7;
	border-radius: 5px;
}

.chatbot-response-bubble-content-card > * { margin-bottom: 1em !important; }
.chatbot-response-bubble-content-card > *:last-child { margin-bottom: 0 !important; }

.chatbot-response-footer {
	display: flex;
	flex-flow: row nowrap;
	justify-content: space-between;
	align-items: flex-start;
	width: 100%;
}

.chatbot-response-bubble-tail {
	display: block;
	position: absolute;
	bottom: 0;
	color: #F7F7F7;
}

.chatbot-response-bubble-tail > * {
	display: block;
	text-shadow: 1px 4px 6px #dddddd;
}

.chatbot-response-question .chatbot-response-bubble-tail { 
	right: 0;
	transform: rotate(-60deg);
}
.chatbot-response-answer .chatbot-response-bubble-tail,
.chatbot-response-loader .chatbot-response-bubble-tail {
	left: 0;
	transform: rotate(60deg);
}

.chatbot-response-question .chatbot-response-bubble-tail > span { 
	transform: scale(0.5,1) translate(40%, 40%);
}
.chatbot-response-answer .chatbot-response-bubble-tail > span,
.chatbot-response-loader .chatbot-response-bubble-tail > span {
	transform: scale(0.5,1) translate(-40%, 40%);
}

.chatbot-response-usericon {
	width: 32px;
	height: 32px;
	border-radius: 50%;
	overflow: hidden;
}

.chatbot-response-answer .chatbot-response-usericon,
.chatbot-response-loader .chatbot-response-usericon { padding: 5px; }

.chatbot-response-usericon > img { width: 100%; height: 100%; }

.chatbot-response-toolbar {
	margin-top: 8px;
	width: calc(100% - 32px);
}

.chatbot-response-question .chatbot-response-toolbar { padding-right: 8px; }
.chatbot-response-answer .chatbot-response-toolbar,
.chatbot-response-loader .chatbot-response-toolbar { padding-left: 8px; }

.chatbot-response-question .chatbot-response-usericon,
.chatbot-response-answer .chatbot-response-toolbar,
.chatbot-response-loader .chatbot-response-toolbar { order: 2; }
.chatbot-response-question .chatbot-response-toolbar,
.chatbot-response-answer .chatbot-response-usericon,
.chatbot-response-loader .chatbot-response-usericon { order: 1; }

.chatbot-response-buttons {
	position: relative;
	display: flex;
	flex-flow: row wrap;
	justify-content: space-between;
	width: 100%;
}

.chatbot-response-button {
	display: block;
	margin-bottom: 8px;
	width: 90px;
	height: 24px;
	line-height: 24px;
	background: #F7F7F7;
	border-radius: 5px;
	border: 0;
	outline: 0;
	font-size: 0.83em !important;
	font-weight: 400;
	text-align: center;
	text-decoration: none;
	cursor: pointer;
}

.chatbot-response-button-blue { color: #6B6EA3; }
.chatbot-response-button-orange { color: #F5650B; }

.chatbot-response-annotation { 
	position: relative;
	padding: 1em 1.5em;
	background: #F7F7F7;
}

/* Accordion */
.toggle-accordion.chatbot-response-button-blue.expanded {
	background: #6B6EA3;
	color: #ffffff;
}
.toggle-accordion.chatbot-response-button-orange.expanded {
	background: #F5650B;
	color: #ffffff;
}

.chatbot-accordion { 
	margin-top: 4px;
	margin-bottom: 12px;
	border-radius: 5px;
	transition: height 0.3s ease-out;
	overflow: hidden;
}

/* Suggested List */
.chatbot-response-suggested {
	margin: 1em 0 !important;
	padding-left: 1em !important;
	color: #3B8CD1;
	list-style-type: none;
}

.chatbot-response-suggested > li {
	position: relative;
	margin-bottom: 1em;
	cursor: pointer;
}

.chatbot-response-suggested > li::before {
	content: '\2010 ';
	display: block;
	position: absolute;
	margin-left: -1em;
	top: 0;
	left: 0;
}

/* Autocomplete */
.chatbot-autocomplete {
	max-height: 125px;
	background: #F7F7F7;
	color: #5C5C5C;
	border-radius: 5px;
	transform: translateY(-100%);
	box-shadow: 1px 1px 6px rgba(0,0,0,0.29);
	overflow: scroll;
}

.chatbot-autocomplete.active { display: block; }

.autocomplete-list {
	padding: 5px 0;
	line-height: 1.25;
}

.autocomplete-item {
	padding: 0 10px;
	opacity: 0.6;
	font-weight: 400;
	cursor: pointer;
}

.autocomplete-item:hover,
.autocomplete-item:focus {
	opacity: 1;
}

.chatbot-message {
	padding: 0.25em;
	min-height: 20px;
	color: #848484;
	font-size: 0.625em !important;
	text-align: center;
}

/* Loader  */
.load-indicator-circle {
	width: 7px;
	height: 7px;
	display: inline-block;
	border-radius: 50%;
	animation: fade 1s infinite;
}

.load-indicator-circle:nth-child(3) {
	animation-delay: -0.3s;
}

.load-indicator-circle:nth-child(2) {
	animation-delay: -0.6s;
}

.load-indicator-circle:nth-child(1) {
	animation-delay: -0.9s;
}

.load-indicator {
	display: inline-block;
	white-space: nowrap;
}

/* Ask a Question link */
.ask-a-question { color: #3B8CD1; cursor: pointer; }

@keyframes fade {
	from { background: #000; }	
	to { background: #ddd; }
}
</style>