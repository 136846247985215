<template>
	<div
		class="polling-question"
	>
		<template v-if="isLoading">
			<div class="w-full min-height h-full flex flex-row justify-center items-center">
				<LoadingSpinner
				></LoadingSpinner>
			</div>
		</template>
		<template v-else>
			<div 
				v-if="isError"
				class="w-full text-center p-x2 font-medium text-20px"
			>
				<p v-html="errorMessage"></p>
			</div>
			<div v-else>
				<header>
					<h2 class="text-14px font-medium">{{ question }}</h2>
				</header>
				<section class="mt-x1">
					<template v-if="isComplete">
						<div>
						</div>
					</template>
					<template v-else>
						<div class="flex flex-col gap-x1">
							<button
								v-for="(choice, choice_ndx) in choices"
								:key="choice_ndx"
								class="p-x1 rounded-default cursor-pointer text-12px"
								:class="[
									classList
								]"
								:style="styling"
								@click="handleSubmit(choice.answer_id )"
							>{{ choice.answer_text }}</button>
						</div>
					</template>
				</section>
			</div>
		</template>
	</div>
</template>

<script setup>
/* eslint-disable vue/no-side-effects-in-computed-properties */
import { defineProps, ref, onMounted, computed } from 'vue'
import { LoadingSpinner } from '@/components/'

// Props
const props = defineProps({
	mediaId: {
		type: String,
		required: true
	},
	userToken: {
		type: String,
		default: null
	},
	buttonBgColor: {
		type: String,
		default: '[#5fafd1]'
	},
	buttonTextColor: {
		type: String,
		default: 'white'
	}
})

// Variables
const isLoading = ref(true)
const isError = ref(false)
const errorMessage = ref(null)
const pollingData = ref(null)

// Computed
const question = computed(() => {
	return pollingData.value?.question
})

const isComplete = computed(() => {
	return pollingData.value?.complete
})

const pollId = computed(() => {
	return pollingData.value?.poll_id
})

const choices = computed(() => {
	return pollingData.value?.choices
		.sort((a, b) => parseInt(a.sort_order) - parseInt(b.sort_order))
})

const results = computed(() => {
	return pollingData.value?.results
})

const isButtonBgColorArbitrary = computed(() => {
	return props.buttonBgColor[0] === '['
})

const isButtonTextColorArbitrary = computed(() => {
	return props.buttonTextColor[0] === '['
})

const classList = computed(() => {
	return `${
		!isButtonBgColorArbitrary.value
		? `bg-${ props.buttonBgColor }`
		: ''
	}${
		!isButtonTextColorArbitrary.value
		? `text-${ props.buttonTextColor }`
		: ''
	}`
})

const styling = computed(() => {
	const output = {}

	if (isButtonBgColorArbitrary.value) output.backgroundColor = props.buttonBgColor.slice(1, -1)
	if (isButtonTextColorArbitrary.value) output.color = props.buttonTextColor.slice(1, -1)

	return output
})

// Life Cycle Hooks
onMounted(async () => {
	console.log(process.env)
	if (!props.mediaId) {
		isError.value = true
		errorMessage.value = 'Missing Media ID'
	}
	else {
		pollingData.value = await getPollingData()
	}
	
	isLoading.value = false
})

// Functions
const getPollingData = async () => {
	const url = `${ 
		process.env.VUE_APP_POLLING_QUESTION_API_URL
	}?media_id=${
		props.mediaId
	}${
		props.userToken
		? `&token=${ props.userToken }`
		: ''
	}`

	try {
		const req = await fetch(url)
		const res = await req.json()

		if (!res?.question) throw ('Missing Data')

		return res
	}
	catch (err) {
		isError.value = true
		errorMessage.value = err
	}
}

const handleSubmit = (ansid) => {
	console.log(ansid)
}
</script>

<style src="@/assets/css/tailwind.css"></style>
<style src="@/assets/css/_fontface.css"></style>
<style src="@/assets/css/_variables.css"></style>
<style src="@/assets/css/_global.css"></style>
<style src="@/assets/css/_animations.css"></style>
<style src="@/assets/css/main.css"></style>