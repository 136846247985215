<template>
  <UiFrame>
    <template v-slot:left>
      <form 
        class="flex flex-col gap-6 h-full" 
        @submit.prevent="handleSubmit"
      >
        <header>
          <h1 class="text-2xl font-medium">Please fill out fields and submit to generate widget</h1>
        </header>
        <section class="flex-1 grid grid-cols-12 gap-4">
          <div class="col-span-6 flex flex-col gap-2">
            <label>Job Num</label>
            <input 
              v-model="payload.jobnum"
              class="p-2 border-1 border-black"
              type="text" 
              required="true"
            >
          </div>
          <div class="col-span-6 flex flex-col gap-2">
            <label>User Token</label>
            <input 
              v-model="payload.token"
              class="p-2 border-1 border-black"
              type="text" 
              required="true"
            >
          </div>
        </section>
        <button 
          class="border-1 border-black p-4"
          @click="handleSubmit"
        >Generate</button>
      </form>
    </template>
    <template v-slot:right>
      <pik-posttest
        v-if="showWidget"
        ref="widgetEl"
        :jobnum="jobnum"
        :token="token"
        @is-completed="(event) => handlePretestCompleted(event)"
        @loaded="initFancyBox"
        @skipped-all="handlePretestSkipped"
      ></pik-posttest>
    </template>
  </UiFrame>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue'
import store from '@/store'
import { UiFrame } from '@/components'

// Life Cycle Hooks
onMounted(() => {
  // handleSubmit()
})

// Variables
const showWidget = ref(false)
const payload = ref({
  jobnum: null, // '2643.02',
  token: null, // '73e93e9bea0732a86467ea990f5b822a', 'd0983291a4f704b9b110fd428c53d6ca'
})

// Computed
const settings = computed(() => {
  return store.getters.widgetSettings
})

const jobnum = computed(() => {
  return settings.value?.jobnum || null
})

const token = computed(() => {
  return settings.value?.token || null
})

// Event Handling
const handleSubmit = () => {
  store.dispatch('submitWidgetSettings', payload.value)
  // Toggle showWidget to force re-render
  showWidget.value = false
  setTimeout(() => {
    showWidget.value = true
  }, 0)
}
</script>

<style src="@/assets/css/tailwind.css"></style>
<style src="@/assets/css/_fontface.css"></style>
<style src="@/assets/css/_variables.css"></style>
<style src="@/assets/css/_global.css"></style>
<style src="@/assets/css/_animations.css"></style>
<style src="@/assets/css/main.css"></style>