<template>
	<div class="accordion-group">
		<AccordionItem
			v-for="(item, item_ndx) in refData"
			:key="item_ndx"
			:data="item"
			:max-height="maxHeight"
			@expand="expandEvent"
			@collapse="collapseEvent"
		/>
	</div>
</template>

<script setup>
import { ref, defineProps } from 'vue'

import { AccordionItem } from '@/components/'

const props = defineProps({
	data: {
		type: Array,
		required: true
	},
	maxHeight: {
		type: String,
		default: null
	},
	expandType: {
		type: String,
		default: 'single'
	},
	defaultExpand: {
		type: String,
		default: () => []
	}
})

const refData = ref(
	props.data
		.map((item, ndx) => {
			return {
				...item,
				ndx,
				is_expanded: props.defaultExpand.includes('all')
					? true
					: props.defaultExpand.includes(ndx + 1)
					? true
					: false
			}
		})
)

const expandEvent = (ndx) => {
	refData.value
		.forEach((item, i) => {
			if (i === ndx) item.is_expanded = true
			if (props.expandType !== 'multi') {
				if (i !== ndx) item.is_expanded = false
			}
		})
}

const collapseEvent = (ndx) => {
	refData.value[ndx].is_expanded = false
}
</script>